import React, { useEffect, useState } from "react";
import "./comment-blog.css";
import { FaEdit, FaTrash } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";

const CommentsPage = () => {
  const [comments, setComments] = useState([]);
  const [editComment, setEditComment] = useState({
    pkBlogCmtId: "",
    name: "",
    email: "",
    webUrl: "",
    comment: "",
    isActive: false,
    slugTitle: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchComments(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchComments = async (pageNo, pageSize) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/blog/search`,
        {
          pkBlogCmtId: "",
          name: "",
          email: "",
          comment: "",
          webUrl: "",
          isActive: "",
          slugTitle: "",
          pageSize: pageSize,
          pageNo: pageNo,
        }
      );

      const { data, totalPages } = response.data;
      setComments(data);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleEditClick = (comment) => {
    setEditComment({
      ...comment,
      isActive: comment.isActive === "1" || comment.isActive === "true",
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditComment((prevComment) => ({
      ...prevComment,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleUpdateComment = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/blog/update/${editComment.pkBlogCmtId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: editComment.name,
            email: editComment.email,
            comment: editComment.comment,
            webUrl: editComment.webUrl,
            isActive: editComment.isActive ? "1" : "0",
            slugTitle: editComment.slugTitle,
          }),
        }
      );

      if (response.ok) {
        await fetchComments(currentPage, pageSize);
        toast.success("Comment updated successfully!", {
          autoClose: 1000,
          position: "bottom-right",
        });
      } else {
        toast.error("Failed to update comment.", {
          autoClose: 1000,
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error("Error updating comment:", error, {
        autoClose: 1000,
        position: "bottom-right",
      });
    }
  };

  const handleDeleteComment = async (pkBlogCmtId) => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/blog/delete/${pkBlogCmtId}`
        );

        if (response.status === 200) {
          await fetchComments(currentPage, pageSize);
          toast.success("Comment deleted successfully!", {
            autoClose: 1000,
            position: "bottom-right",
          });
        } else {
          toast.error("Failed to delete comment.", {
            autoClose: 1000,
            position: "bottom-right",
          });
        }
      } catch (error) {
        toast.error("Error deleting comment:", error, {
          autoClose: 1000,
          position: "bottom-right",
        });
      }
    }
  };

  return (
    <>
      <section className="comment-blog-section position-relative">
        <div className="comment-blog-hero-section">
          <div className="comment-blog-hero-section-content"></div>
        </div>
        <div className="comment-blog-body-section py-5">
          <div className="our-container">
            <div className="inner-container">
              <div className="comment-blog-body table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Website URL</th>
                      <th scope="col">Slug - Blog Name</th>
                      <th scope="col">Comment</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {comments.map((comment, index) => (
                      <tr key={comment.pkBlogCmtId}>
                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                        <td>{comment.name}</td>
                        <td>{comment.email}</td>
                        <td>{comment.webUrl}</td>
                        <td>{comment.blogName}</td>
                        <td>{comment.comment}</td>
                        <td>
                          <button
                            type="button"
                            className="btn action-icon-edit-link"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() => handleEditClick(comment)}
                          >
                            <FaEdit />
                          </button>
                          <button
                            className="action-icon-delete-btn"
                            onClick={() =>
                              handleDeleteComment(comment.pkBlogCmtId)
                            }
                          >
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade comment-modal"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Edit Comment Form
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleUpdateComment}>
                <div className="d-flex align-items-center flex-column mb-4 comment-form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={editComment.name}
                    onChange={handleInputChange}
                    autoComplete="off"
                    className="px-2 rounded-0"
                    placeholder="Your Name"
                    readOnly
                  />
                </div>
                <div className="d-flex align-items-center flex-column mb-4 comment-form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={editComment.email}
                    onChange={handleInputChange}
                    autoComplete="off"
                    className="px-2 rounded-0"
                    placeholder="Your Email"
                    readOnly
                  />
                </div>
                <div className="d-flex align-items-center flex-column mb-4 comment-form-group">
                  <input
                    type="text"
                    id="webUrl"
                    name="webUrl"
                    value={editComment.webUrl}
                    onChange={handleInputChange}
                    autoComplete="off"
                    className="px-2 rounded-0"
                    placeholder="Your websiteURL"
                    readOnly
                  />
                </div>
                <div className="d-flex align-items-center flex-column mb-4 comment-form-group">
                  <input
                    type="text"
                    id="comment"
                    name="comment"
                    value={editComment.comment}
                    onChange={handleInputChange}
                    autoComplete="off"
                    className="px-2 rounded-0"
                    placeholder="Your Comment"
                    readOnly
                  />
                </div>
                <div className="d-flex flex-column mb-4 comment-form-group">
                  <label htmlFor="isActive">Is Active</label>
                  <input
                    type="checkbox"
                    id="isActive"
                    name="isActive"
                    checked={editComment.isActive}
                    onChange={handleInputChange}
                    autoComplete="off"
                    className="px-2 rounded-0"
                    style={{ width: "24px" }}
                  />
                </div>
                <button
                  type="submit"
                  className="text-capitalize comment-form-button"
                >
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentsPage;

// import React, { useEffect, useState } from "react";
// import "./comment-blog.css";
// import { FaEdit, FaTrash } from "react-icons/fa";
// import axios from "axios";

// const CommentsPage = () => {
//   const [comments, setComments] = useState([]);
//   const [editComment, setEditComment] = useState({
//     pkBlogCmtId: "",
//     name: "",
//     email: "",
//     webUrl: "",
//     comment: "",
//     isActive: false,
//     slugTitle: "",
//   });
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(10);
//   const [totalPages, setTotalPages] = useState(0);

//   useEffect(() => {
//     fetchComments(currentPage, pageSize);
//   }, [currentPage, pageSize]);

//   const fetchComments = async (pageNo, pageSize) => {
//     try {
//       const response = await axios.post(`${process.env.REACT_APP_API_URL}/blog/search`, {
//         pkBlogCmtId: "",
//         name: "",
//         email: "",
//         comment: "",
//         webUrl: "",
//         isActive: "",
//         slugTitle: "",
//         pageSize: pageSize,
//         pageNo: pageNo
//       });

//       const { data, totalPages } = response.data;
//       setComments(data);
//       setTotalPages(totalPages);
//     } catch (error) {
//       console.error("Error fetching comments:", error);
//     }
//   };

//   const handleEditClick = (comment) => {
//     setEditComment(comment);
//   };

//   const handleInputChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setEditComment((prevComment) => ({
//       ...prevComment,
//       [name]: type === "checkbox" ? checked : value,
//     }));
//   };

//   const handleUpdateComment = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/blog/update/${editComment.pkBlogCmtId}`,
//         {
//           method: "PUT",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             name: editComment.name,
//             email: editComment.email,
//             comment: editComment.comment,
//             webUrl: editComment.webUrl,
//             isActive: editComment.isActive ? "1" : "0",
//             slugTitle: editComment.slugTitle,
//           }),
//         }
//       );

//       if (response.ok) {
//         await fetchComments(); // Refresh the comments list
//         alert("Comment updated successfully!");
//       } else {
//         alert("Failed to update comment.");
//       }
//     } catch (error) {
//       console.error("Error updating comment:", error);
//     }
//   };

//   // const handlePageChange = (newPage) => {
//   //   if (newPage > 0 && newPage <= totalPages) {
//   //     setCurrentPage(newPage);
//   //   }
//   // };

//   // const handlePageSizeChange = (event) => {
//   //   setPageSize(parseInt(event.target.value, 10));
//   // };

//   return (
//     <>
//       <section className="comment-blog-section position-relative">
//         <div className="comment-blog-hero-section">
//           <div className="comment-blog-hero-section-content"></div>
//         </div>
//         <div className="comment-blog-body-section py-5">
//           <div className="our-container">
//             <div className="inner-container">
//               <div className="comment-blog-body table-responsive">
//                 <table className="table">
//                   <thead>
//                     <tr>
//                       <th scope="col">#</th>
//                       <th scope="col">Name</th>
//                       <th scope="col">Email</th>
//                       <th scope="col">Website URL</th>
//                       <th scope="col">Comment</th>
//                       <th scope="col">Actions</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                   {comments.map((comment, index) => (
//                       <tr key={comment.pkBlogCmtId}>
//                         <td>{(currentPage - 1) * pageSize + index + 1}</td>
//                         <td>{comment.name}</td>
//                         <td>{comment.email}</td>
//                         <td>{comment.webUrl}</td>
//                         <td>{comment.comment}</td>
//                         <td>
//                           <button
//                             type="button"
//                             className="btn action-icon-edit-link"
//                             data-bs-toggle="modal"
//                             data-bs-target="#exampleModal"
//                           >
//                             <FaEdit />
//                           </button>
//                           <button className="action-icon-delete-btn">
//                             <FaTrash />
//                           </button>
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//                 {/* <div className="pagination-controls">
//                   <label>
//                     Show
//                     <select value={pageSize} onChange={handlePageSizeChange}>
//                       <option value={10}>10</option>
//                       <option value={20}>20</option>
//                       <option value={30}>30</option>
//                     </select>
//                     Products per Page
//                   </label>
//                   <div className="pagination-buttons">
//                     <button
//                       className="pagination-btn"
//                       onClick={() => handlePageChange(currentPage - 1)}
//                       disabled={currentPage === 1}
//                     >
//                       Previous
//                     </button>
//                     <span>
//                       Page {currentPage} of {totalPages}
//                     </span>
//                     <button
//                       className="pagination-btn"
//                       onClick={() => handlePageChange(currentPage + 1)}
//                       disabled={currentPage >= totalPages}
//                     >
//                       Next
//                     </button>
//                   </div>
//                 </div> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <div
//         className="modal fade comment-modal"
//         id="exampleModal"
//         tabindex="-1"
//         aria-labelled-by="exampleModalLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-dialog-centered">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h1 className="modal-title fs-5" id="exampleModalLabel">
//                 Edit Comment Form
//               </h1>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//               ></button>
//             </div>
//             <div className="modal-body">
//               <form onSubmit={handleUpdateComment}>
//                 <div className="d-flex align-items-center flex-column mb-4 comment-form-group">
//                   <input
//                     type="text"
//                     id="name"
//                     name="name"
//                     value={editComment.name}
//                     onChange={handleInputChange}
//                     autoComplete="off"
//                     className="px-2 rounded-0"
//                     placeholder="Your Name"
//                     // readOnly
//                   />
//                 </div>
//                 <div className="d-flex align-items-center flex-column mb-4 comment-form-group">
//                   <input
//                     type="email"
//                     id="email"
//                     name="email"
//                     value={editComment.email}
//                     onChange={handleInputChange}
//                     autoComplete="off"
//                     className="px-2 rounded-0"
//                     placeholder="Your Email"
//                     // readOnly
//                   />
//                 </div>
//                 <div className="d-flex align-items-center flex-column mb-4 comment-form-group">
//                   <input
//                     type="text"
//                     id="webUrl"
//                     name="webUrl"
//                     value={editComment.webUrl}
//                     onChange={handleInputChange}
//                     autoComplete="off"
//                     className="px-2 rounded-0"
//                     placeholder="Your websiteURL"
//                     // readOnly
//                   />
//                 </div>
//                 <div className="d-flex align-items-center flex-column mb-4 comment-form-group">
//                   <input
//                     type="text"
//                     id="comment"
//                     name="comment"
//                     value={editComment.comment}
//                     onChange={handleInputChange}
//                     autoComplete="off"
//                     className="px-2 rounded-0"
//                     placeholder="Your Comment"
//                     // readOnly
//                   />
//                 </div>
//                 <div className="d-flex flex-column mb-4 comment-form-group">
//                   <label htmlFor="isActive">Is Active</label>
//                   <input
//                     type="checkbox"
//                     id="isActive"
//                     name="isActive"
//                     checked={editComment.isActive}
//                     onChange={handleInputChange}
//                     autoComplete="off"
//                     className="px-2 rounded-0"
//                     style={{ width: "24px" }}
//                   />
//                 </div>
//                 <button
//                   type="submit"
//                   className="text-capitalize comment-form-button"
//                 >
//                   Send
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default CommentsPage;

// // import React, { useState, useEffect } from 'react';

// // // Dummy API call (simulated with in-memory data)
// // const fetchComments = () => {
// //   return [
// //     { id: 1, text: 'This is a great post!' },
// //     { id: 2, text: 'Thanks for sharing this.' },
// //     { id: 3, text: 'Awesome, I learned a lot!' }
// //   ];
// // };

// // const CommentsPage = () => {
// //   const [comments, setComments] = useState([]);

// //   useEffect(() => {
// //     // Fetch comments from the dummy API
// //     setComments(fetchComments());
// //   }, []);

// //   const handleEdit = (id, newText) => {
// //     setComments(comments.map(comment =>
// //       comment.id === id ? { ...comment, text: newText } : comment
// //     ));
// //   };

// //   const handleDelete = (id) => {
// //     setComments(comments.filter(comment => comment.id !== id));
// //   };

// //   return (
// //     <div>
// //       <h1>Comments</h1>
// //       {comments.map(comment => (
// //         <div key={comment.id}>
// //           <Comment
// //             comment={comment}
// //             onEdit={handleEdit}
// //             onDelete={handleDelete}
// //           />
// //         </div>
// //       ))}
// //     </div>
// //   );
// // };

// // const Comment = ({ comment, onEdit, onDelete }) => {
// //   const [isEditing, setIsEditing] = useState(false);
// //   const [newText, setNewText] = useState(comment.text);

// //   const handleEdit = () => {
// //     onEdit(comment.id, newText);
// //     setIsEditing(false);
// //   };

// //   return (
// //     <div>
// //       {isEditing ? (
// //         <div>
// //           <input
// //             type="text"
// //             value={newText}
// //             onChange={(e) => setNewText(e.target.value)}
// //           />
// //           <button onClick={handleEdit}>Save</button>
// //         </div>
// //       ) : (
// //         <p>{comment.text}</p>
// //       )}
// //       <button onClick={() => setIsEditing(!isEditing)}>
// //         {isEditing ? 'Cancel' : 'Edit'}
// //       </button>
// //       <button onClick={() => onDelete(comment.id)}>Delete</button>
// //     </div>
// //   );
// // };

// // export default CommentsPage;
