import React from "react";
import "./seo.css";
import LazyLoad from "react-lazyload";
import ContactForm from "../../../components/ContactForm";
import MetaDescTag from "../../../components/MetaDescTag";
import { seoCardParaDetails, seoCardSection } from "../../../staticData/data";

const SEO = () => {
  return (
    <>
      <MetaDescTag
        title={"Search Engine Optimization | KARN Marketing Warfare LLP"}
        description={
          "With our SEO services like on-page optimization, Technical optimization, off-page optimization, local SEO, and content optimization KARN will assist businesses to reinforce to find their target audience and help to boost business."
        }
      />
      <section className="seo-section position-relative">
        <div className="seo-hero-section">
          {/* <picture>
            <source
              media="(min-width:800px)"
              srcset="../../assets/seo/seo-hero-banner.jpg"
            />
            <img src="../../assets/seo/seo-small-device.jpg" alt="" />
          </picture> */}
          <LazyLoad offset={100}>
            <picture>
              <source
                media="(min-width:800px)"
                srcSet="../../assets/seo/seo-hero-banner.jpg"
              />
              <img
                src="../../assets/seo/seo-small-device.jpg"
                alt="KARN Marketing SEO Banner"
                loading="lazy"
                width="100%"
                height="auto"
              />
            </picture>
          </LazyLoad>
          <div className="seo-hero-section-content">
            <div className="h1 m-0">
              Search Engine <br />
              <img src="../../assets/seo/seo-heading-icon.png" alt="" />
              ptimization
            </div>
          </div>
        </div>
        <div className=" seo-header-section position-relative py-5">
          <div className="our-container">
            <div className="h5">Search Engine Optimization</div>
            <p className="fw-bold text-capitalize">
              Best SEO Agency In Navi Mumbai
            </p>
            <p>
              KARN Marketing Warfare LLP is the first-rate{" "}
              <h1
                style={{ display: "inline-block", fontSize: "1rem" }}
                className="m-0"
              >
                SEO Company In Navi Mumbai
              </h1>{" "}
              for business across the city. With our SEO services like on-page
              optimization, Technical optimization, off-page optimization, local
              SEO, and content optimization KARN will assist businesses to
              reinforce to find their target audience and help to boost
              business.
            </p>
            <div className="seo-header-details-row">
              {seoCardParaDetails.map((item, id) => {
                return (
                  <>
                    <div className="seo-header-details-block" key={id}>
                      <p className="fw-bold text-capitalize">
                        {item.seoCardParaDetailsTitle}
                      </p>
                      <p>{item.seoCardParaDetailsDesc}</p>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
        <div className=" seo-body-section py-5">
          <div className="our-container">
            <div className=" seo-body-header-section">
              <div className="h5">Why</div>
              <div className="h2 text-capitalize">
                SEO Important For Your Business
              </div>
              <div className=" seo-body-header-border"></div>
            </div>
            <div className=" seo-body-card-section">
              <div className=" seo-card-row">
                {seoCardSection.map((item, id) => {
                  return (
                    <>
                      <div className=" seo-card-block" key={id}>
                        <div className=" seo-card-icon">
                          <img src={item.seoIconPath} alt="" />
                        </div>
                        <div className=" seo-card-heading">
                          <div className="h5 text-capitalize">
                            {item.seoTitle}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <ContactForm />
      </section>
    </>
  );
};

export default SEO;
