import React, { useState } from "react";
import "./blog.css";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import ContactForm from "../../components/ContactForm";
import Pagination from "../../components/Pagination";
import MetaDescTag from "../../components/MetaDescTag";
import { blogs } from "../../staticData/data";

const Blog = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 5;
  const totalPages = Math.ceil(blogs.length / blogsPerPage);

  // Sort blogs by id in descending order
  const sortedBlogs = blogs.sort((a, b) => b.id - a.id);

  const truncate = (str, n) => {
    return str.split(" ").splice(0, n).join(" ");
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const currentBlogs = sortedBlogs.slice(
    (currentPage - 1) * blogsPerPage,
    currentPage * blogsPerPage
  );

  return (
    <>
      <MetaDescTag
        title={"BLOGS | KARN Marketing Warfare LLP"}
        description={
          "At KARN Marketing Warfare LLP, we are not just a digital marketing agency—we are your allies in the battlefield of online competition. Founded with a vision to empower businesses through innovative digital strategies, we specialize in creating campaigns that don’t just compete but dominate."
        }
      />
      <section className="blog-section position-relative">
        <div className="blog-hero-section">
          {/* <picture>
            <source
              media="(min-width:800px)"
              srcset="../../assets/blog/blog-hero-banner.jpg"
            />
            <img src="../../assets/blog/blog-small-device.jpg" alt="" />
          </picture> */}
          <LazyLoad offset={100}>
            <picture>
              <source
                media="(min-width:800px)"
                srcSet="../../assets/blog/blog-hero-banner.jpg"
              />
              <img
                src="../../assets/blog/blog-small-device.jpg"
                alt="KARN Marketing Blog  Banner"
                loading="lazy"
                width="100%"
                height="auto"
              />
            </picture>
          </LazyLoad>
          <div className="blog-hero-section-content">
            <div className="h1 m-0">Blog</div>
          </div>
        </div>
        <div className=" blog-body-section position-relative py-5">
          <div className="our-container">
            <div className="inner-container">
              <h1 className="m-0 d-none">KARN Marketing Warfare LLP</h1>
              <div className="blog-section-body-row">
                {currentBlogs.map((item, id) => {
                  return (
                    <div className="blog-section-block" key={id}>
                      <Link
                        to={`/blog/${item.slug}`}
                        className="blog-section-block-img"
                      >
                        <img src={item.blogImg} alt="" />
                      </Link>
                      <div className="blog-section-block-content">
                        <Link
                          to={`/blog/${item.slug}`}
                          className="h5 text-capitalize"
                        >
                          {item.blogTitle}
                        </Link>
                        <p>{truncate(item.blogDesc, 100)} [...]</p>
                      </div>
                      <Link
                        to={`/blog/${item.slug}`}
                        className="blog-section-block-learn-more-link"
                      >
                        <span>Learn More</span>{" "}
                        <MdOutlineKeyboardDoubleArrowRight />
                      </Link>
                    </div>
                  );
                })}
              </div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
        <ContactForm />
      </section>
    </>
  );
};

export default Blog;
