import React from "react";
import "./website-development.css";
import LazyLoad from "react-lazyload";
import ContactForm from "../../../components/ContactForm";
import MetaDescTag from "../../../components/MetaDescTag";
import {
  websiteDevelopmentCardParaDetails,
  websiteDevelopmentCardSection,
} from "../../../staticData/data";

const WebsiteDevelopment = () => {
  return (
    <>
      <MetaDescTag
        title={"Website Development | KARN Marketing Warfare LLP"}
        description={
          "We create amazing User-friendly websites in a very good manner to not great hold your clients engaged but also preserve them. Websites designed and advanced through us will seamlessly scale at some stage in most cell gadgets. Whether you are looking for a glowing layout or upgrading a gift website."
        }
      />
      <section className="website-development-section position-relative">
        <div className="website-development-hero-section">
          {/* <picture>
            <source
              media="(min-width:800px)"
              srcset="../../assets/website-development/website-development-hero-banner.jpg"
            />
            <img
              src="../../assets/website-development/website-development-small-device.jpg"
              alt=""
            />
          </picture> */}
          <LazyLoad offset={100}>
            <picture>
              <source
                media="(min-width:800px)"
                srcSet="../../assets/website-development/website-development-hero-banner.jpg"
              />
              <img
                src="../../assets/website-development/website-development-small-device.jpg"
                alt="KARN Marketing Website Development Banner"
                loading="lazy"
                width="100%"
                height="auto"
              />
            </picture>
          </LazyLoad>
          <div className="website-development-hero-section-content">
            <div className="h1 m-0">
              website <br />
              development
            </div>
          </div>
        </div>
        <div className=" website-development-header-section position-relative py-5">
          <div className="our-container">
            <div className="h5">Website Development</div>
            <p className="fw-bold text-capitalize">
              We create high-performing websites that drive results
            </p>
            <p>
              We create amazing User-friendly websites in a very good manner to
              not great hold your clients engaged but also preserve them.
              Websites designed and advanced through us will seamlessly scale at
              some stage in most cell gadgets. Whether you are looking for a
              glowing layout or upgrading a gift website,{" "}
              <h1
                style={{ display: "inline-block", fontSize: "1rem" }}
                className="m-0"
              >
                KARN Marketing Warfare LLP
              </h1>{" "}
              has the knowledge to grow bespoke and placing web websites within
              a brief period.
            </p>
            <p
              className="fw-bold text-capitalize"
              style={{ color: "#0f1d55", fontSize: "1.5rem" }}
            >
              Why Our Website Design Services Outshine The Rest
            </p>
            <div className="website-development-header-details-row">
              {websiteDevelopmentCardParaDetails.map((item, id) => {
                return (
                  <>
                    <div
                      className="website-development-header-details-block"
                      key={id}
                    >
                      <p className="fw-bold text-capitalize">
                        {item.websiteDevelopmentCardParaDetailsTitle}
                      </p>
                      <p>{item.websiteDevelopmentCardParaDetailsDesc}</p>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
        <div className=" website-development-body-section py-5">
          <div className="our-container">
            <div className=" website-development-body-header-section">
              <div className="h5">Creative</div>
              <div className="h2 text-capitalize">Web Design Process</div>
              <div className=" website-development-body-header-border"></div>
            </div>
            <div className=" website-development-body-card-section">
              <div className=" website-development-card-row">
                {websiteDevelopmentCardSection.map((item, id) => {
                  return (
                    <>
                      <div className=" website-development-card-block" key={id}>
                        <div className=" website-development-card-icon">
                          <img src={item.websiteDevelopmentIconPath} alt="" />
                        </div>
                        <div className=" website-development-card-heading">
                          <div className="h5 text-capitalize">
                            {item.websiteDevelopmentTitle}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <ContactForm />
      </section>
    </>
  );
};

export default WebsiteDevelopment;
