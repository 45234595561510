import React from "react";
import "./not-found.css";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <section className="not-found-page">
        <div className="not-found-page-detail-hero-section"></div>
        <div className="not-found-page-block">
          <div className="our-container">
            <div className="inner-container">
              <div className="content-page-details">
                <div className="not-found-img-block">
                  <img src="../../assets/404-img.png" alt="" />
                </div>
                <div className="not-found-content-block">
                  <h1>404</h1>
                  <h3>Something is Missing.</h3>
                  <p>The page you are looking for cannot be found. </p>
                  <p>Take a break before trying again </p>
                  <Link to={"/"} className="not-found-content-link">
                    Back To HomePage
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
