import React, { useState } from "react";
import "./header.css";
import { Link, NavLink } from "react-router-dom";
import { CiMenuFries } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import { headerData } from "../../staticData/data";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header>
        <div className="header-section our-container">
          <Link to={"/"} className="header-logo-block">
            <img src="../../assets/karn-logo.png" alt="" />
          </Link>
          <div className="header-menu-icon-block" onClick={toggleMenu}>
            <CiMenuFries className="header-menu-icon" />
          </div>
          <ul className={isOpen ? "nav-menu active" : "nav-menu"}>
            <div
              className={`header-menu-icon-close-block ${
                isOpen ? "active" : ""
              }`}
              onClick={toggleMenu}
            >
              <RxCross2 className="header-menu-close-icon" />
            </div>
            <li className="nav-item">
              <NavLink
                to={"/"}
                onClick={() => {
                  setIsOpen(false);
                }}
                className="nav-links"
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item dropdown">
              <span className="nav-links">Services</span>
              <ul className="dropdown-menu">
                {headerData.map((item, id) => {
                  return (
                    <li key={id}>
                      <NavLink
                        to={item.navURL}
                        onClick={() => {
                          setIsOpen(false);
                        }}
                        className="dropdown-link"
                      >
                        {item.navTitle}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </li>
            <li className="nav-item">
              <NavLink
                to={"/about-us"}
                onClick={() => {
                  setIsOpen(false);
                }}
                className="nav-links"
              >
                About Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={"/blog"}
                onClick={() => {
                  setIsOpen(false);
                }}
                className="nav-links"
              >
                Blog
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={"/contact-us"}
                onClick={() => {
                  setIsOpen(false);
                }}
                className="nav-links"
              >
                Contact Us
              </NavLink>
            </li>
            <button
              type="button"
              className="btn header-enquiry-link"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              Enquiry Now
            </button>
          </ul>
        </div>
      </header>
    </>
  );
};

export default Header;
