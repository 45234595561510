import React from "react";
import "./social-media-marketing.css";
import LazyLoad from "react-lazyload";
import ContactForm from "../../../components/ContactForm";
import MetaDescTag from "../../../components/MetaDescTag";
import { socialMediaMarketingCardSection } from "../../../staticData/data";

const SocialMediaMarketing = () => {
  return (
    <>
      <MetaDescTag
        title={"Social Media Marketing | KARN Marketing Warfare LLP"}
        description={
          "Social media marketing is the method of promoting a service or product via numerous social media platforms. You must have effective social media marketing techniques in the vicinity. It will assist together with your website’s ranking in online searches, assist with your online recognition, boost your core network, and permit you to construct new relationships."
        }
      />
      <section className="social-media-marketing-section position-relative">
        <div className="social-media-marketing-hero-section">
          {/* <picture>
            <source
              media="(min-width:800px)"
              srcset="../../assets/social-media-marketing/social-media-marketing-hero-banner.jpg"
            />
            <img
              src="../../assets/social-media-marketing/social-media-marketing-small-device.jpg"
              alt=""
            />
          </picture> */}
          <LazyLoad offset={100}>
            <picture>
              <source
                media="(min-width:800px)"
                srcSet="../../assets/social-media-marketing/social-media-marketing-hero-banner.jpg"
              />
              <img
                src="../../assets/social-media-marketing/social-media-marketing-small-device.jpg"
                alt="KARN Marketing SMM Banner"
                loading="lazy"
                width="100%"
                height="auto"
              />
            </picture>
          </LazyLoad>
          <div className="social-media-marketing-hero-section-content">
            <div className="h1 m-0">
              Social Media <br />
              Marketing
            </div>
          </div>
        </div>
        <div className=" social-media-marketing-header-section position-relative py-5">
          <div className="our-container">
            <div className="h5">Social Media Marketing</div>
            <p>
              Social media marketing is the method of promoting a service or
              product via numerous social media platforms. You must have
              effective social media marketing techniques in the vicinity. It
              will assist together with your website’s ranking in online
              searches, assist with your online recognition, boost your core
              network, and permit you to construct new relationships.
            </p>
            <p>
              Free and Paid advertising campaigns on social media will
              intensively raise your enterprise. In trendy, there are methods to
              carry out a social media advertising campaign.
            </p>
            <h1 className="text-center text-capitalize">
              KARN Marketing Warfare LLP: Your Social Media Partner
            </h1>
            <p>
              Social Media Strategy Development: Defining your goals, target
              audience, and content calendar. Content Creation: Developing
              engaging content that resonates with your audience. Community
              Management: Building and nurturing a strong online community.
              Social Media Advertising: Running targeted campaigns to reach your
              ideal customers. Analytics and Reporting: Measuring campaign
              performance and providing actionable insights. Let KARN Marketing
              Warfare LLP help you create a strong social media presence that
              drives results.
            </p>
            <p className="fw-bold text-capitalize">
              Why is Social Media Marketing Important?
            </p>
            <p>
              Reach a Wider Audience: Connect with potential customers where
              they spend most of their time. Build Brand Loyalty: Foster
              relationships with your audience through consistent engagement.
              Increase Website Traffic: Drive traffic to your website through
              compelling social media content. Generate Leads: Convert social
              media followers into potential customers. Improve SEO: Active
              social media presence can positively impact your search engine
              rankings.
            </p>
          </div>
        </div>
        <div className=" social-media-marketing-body-section py-5">
          <div className="our-container">
            <div className=" social-media-marketing-body-header-section">
              <div className="h5">KARN MARKETING WARFARE LLP</div>
              <div className="h2 text-capitalize">
                Boost Your Social Media Presence
              </div>
              <div className=" social-media-marketing-body-header-border"></div>
            </div>
            <div className=" social-media-marketing-body-card-section">
              <div className=" social-media-marketing-card-row">
                {socialMediaMarketingCardSection.map((item, id) => {
                  return (
                    <>
                      <div
                        className=" social-media-marketing-card-block"
                        key={id}
                      >
                        <div className=" social-media-marketing-card-icon">
                          <img src={item.socialMediaMarketingIconPath} alt="" />
                        </div>
                        <div className=" social-media-marketing-card-heading">
                          <div className="h5 text-capitalize">
                            {item.socialMediaMarketingTitle}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <ContactForm />
      </section>
    </>
  );
};

export default SocialMediaMarketing;
