import React from "react";
import "./contact.css";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { FaPhoneAlt } from "react-icons/fa";
import ContactForm from "../../components/ContactForm";
import MetaDescTag from "../../components/MetaDescTag";
import { faqSection } from "../../staticData/data";

const Contact = () => {
  return (
    <>
      <MetaDescTag
        title={"Contact Us | KARN Marketing Warfare LLP"}
        description={
          "Ready to take your business to new heights with innovative digital marketing strategies? KARN Marketing Warfare LLP is here to help you achieve your goals."
        }
      />
      <section className="contact-section position-relative">
        <div className="contact-hero-section">
          {/* <picture>
            <source
              media="(min-width:800px)"
              srcset="../../assets/contact/contact-us-hero-banner.jpg"
            />
            <img src="../../assets/contact/contact-small-device.jpg" alt="" />
          </picture> */}
          <LazyLoad offset={100}>
            <picture>
              <source
                media="(min-width:800px)"
                srcSet="../../assets/contact/contact-us-hero-banner.jpg"
              />
              <img
                src="../../assets/contact/contact-small-device.jpg"
                alt="KARN Marketing Contact Us  Banner"
                loading="lazy"
                width="100%"
                height="auto"
              />
            </picture>
          </LazyLoad>
          <div className="contact-hero-section-content">
            <div className="h1 m-0">Contact Us</div>
          </div>
        </div>
        <div className="contact-header-section py-5">
          <div className="our-container">
            <div className="contact-header-row">
              <div className="contact-header-block">
                <div className="h5">Let's Make it Happens</div>
                <div className="h2">
                  Don't Hesitate To <br /> Get in Touch.
                </div>
                <div className="contact-header-border"></div>
                <p className="mb-0">
                  Ready to take your business to new heights with innovative
                  digital marketing strategies?{" "}
                  <h1
                    style={{ display: "inline-block", fontSize: "1rem" }}
                    className="m-0"
                  >
                    KARN Marketing Warfare LLP
                  </h1>{" "}
                  is here to help you achieve your goals.
                </p>
              </div>
              <div className="contact-header-contact-us">
                <div className="mb-5">
                  <div className="contact-header-contact-us-block mb-1">
                    <MdEmail
                      className="contact-header-contact-us-icon"
                      style={{ fontSize: "24px" }}
                    />
                    <p className="mb-0 text-uppercase">Email</p>
                  </div>
                  <Link
                    to={"mailto:karnmarketingwarfarellp@gmail.com"}
                    className="contact-header-contact-us-block-link mb-0 ps-5"
                  >
                    karnmarketingwarfarellp@gmail.com
                  </Link>
                </div>
                <div className="mb-5">
                  <div className="contact-header-contact-us-block mb-1">
                    <FaPhoneAlt
                      className="contact-header-contact-us-icon"
                      style={{ fontSize: "23px" }}
                    />
                    <p className="mb-0 text-uppercase">Phone</p>
                  </div>
                  <Link
                    to={"tel:7304675307"}
                    className="contact-header-contact-us-block-link mb-0 ps-5"
                  >
                    7304675307
                  </Link>
                </div>
                <div>
                  <div className="contact-header-contact-us-block mb-1">
                    <FaLocationDot
                      className="contact-header-contact-us-icon"
                      style={{ fontSize: "24px" }}
                    />
                    <p className="mb-0 text-uppercase">Address</p>
                  </div>
                  <Link
                    to={"https://maps.app.goo.gl/aD7ZahbRWi5cGb3U8"}
                    className="contact-header-contact-us-block-link mb-0 ps-5"
                  >
                    Office no 7, Ambe Prerna Cooperative Housing Society, Plot
                    no 8, Near Central Park, Sector 3, Ghansoli, Navi Mumbai,
                    Maharashtra 400701
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-body-section-faq py-5">
          <div className="our-container">
            <div className="h5">FAQ's</div>
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              {faqSection.map((item, id) => {
                return (
                  <>
                    <div className="accordion-item" key={id}>
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse-${id}`}
                          aria-expanded="false"
                          aria-controls={`flush-collapse-${id}`}
                        >
                          {item.faqQuestion}
                        </button>
                      </h2>
                      <div
                        id={`flush-collapse-${id}`}
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">{item.faqAnswer}</div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
        <ContactForm />
      </section>
    </>
  );
};

export default Contact;
