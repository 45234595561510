import React from "react";
import "./footer.css";
import { Link, NavLink } from "react-router-dom";
import {
  FaFacebookSquare,
  FaInstagram,
  FaPhoneAlt,
  FaYoutube,
} from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-section position-relative">
          <div className="our-container">
            <div className="footer-top-section">
              <div className="footer-top-section-row">
                <div className="footer-top-section-logo-content">
                  <div className="footer-logo-block">
                    <Link to={"/"} className="header-logo-block">
                      <img src="../../assets/karn-logo.png" alt="" />
                    </Link>
                  </div>
                  <p className="footer-desc-block">
                    KARN Marketing Warfare LLP: Your strategic partner in
                    digital dominance, crafting campaigns that conquer the
                    competition.
                  </p>
                  <div className="footer-social-icons-row">
                    <Link
                      to={"https://www.facebook.com/karnmarketing"}
                      target="_blank"
                      className="footer-social-icon-link"
                    >
                      <FaFacebookSquare className="footer-social-icon" />
                    </Link>
                    <Link
                      to={"https://www.instagram.com/karnmarketing/"}
                      target="_blank"
                      className="footer-social-icon-link"
                    >
                      <FaInstagram className="footer-social-icon" />
                    </Link>
                    <Link
                      to={"https://www.youtube.com/@KarnMarketingWarfareLLP/"}
                      target="_blank"
                      className="footer-social-icon-link"
                    >
                      <FaYoutube className="footer-social-icon" />
                    </Link>
                  </div>
                </div>
                <div className="footer-navLinks">
                  <div className="h5">Nav Links</div>
                  <ul>
                    <li className="nav-item mb-3">
                      <NavLink to={"/"} className="nav-links">
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item mb-3">
                      <NavLink to={"/about-us"} className="nav-links">
                        About
                      </NavLink>
                    </li>
                    <li className="nav-item mb-3">
                      <NavLink to={"/blog"} className="nav-links">
                        Blog
                      </NavLink>
                    </li>
                    <li className="nav-item mb-3">
                      <NavLink to={"/contact-us"} className="nav-links">
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="footer-contact-us">
                  <div className="h5">Contact Us</div>
                  <div className="footer-contact-us-block mb-3">
                    <MdEmail className="footer-contact-us-icon" />
                    <Link
                      to={"mailto:karnmarketingwarfarellp@gmail.com"}
                      className="footer-contact-us-block-link"
                    >
                      karnmarketingwarfarellp@gmail.com
                    </Link>
                  </div>
                  <div className="footer-contact-us-block mb-3">
                    <FaPhoneAlt
                      className="footer-contact-us-icon"
                      style={{ fontSize: "19px" }}
                    />
                    <Link
                      to={"tel:7304675307"}
                      className="footer-contact-us-block-link"
                    >
                      7304675307
                    </Link>
                  </div>
                  <div className="footer-contact-us-block mb-3">
                    <FaLocationDot
                      className="footer-contact-us-icon location-icon"
                      style={{ fontSize: "52px" }}
                    />
                    <Link
                      to={"https://maps.app.goo.gl/aD7ZahbRWi5cGb3U8"}
                      className="footer-contact-us-block-link"
                    >
                      Office no 7, Ambe Prerna Cooperative Housing Society, Plot
                      no 8, Near Central Park, Sector 3, Ghansoli, Navi Mumbai,
                      Maharashtra 400701
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom-section-block">
              <p className="mb-0">
                ©{new Date().getFullYear()} Karnllp. All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
