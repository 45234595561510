import React from "react";
import "./Influencer-marketing.css";
import LazyLoad from "react-lazyload";
import ContactForm from "../../../components/ContactForm";
import MetaDescTag from "../../../components/MetaDescTag";
import { influencerMarketingCardSection } from "../../../staticData/data";

const InfluencerMarketing = () => {
  return (
    <>
      <MetaDescTag
        title={"Influencer Marketing|KARN Marketing Warfare LLP"}
        description={
          "At KARN Marketing Warfare LLP, we specialize in crafting impactful influencer marketing campaigns that deliver results. Our team of experts will Identify the Right Influencers: We meticulously select influencers who align with your brand values and target audience."
        }
      />
      <section className="influencer-marketing-section position-relative">
        <div className="influencer-marketing-hero-section">
          {/* <picture>
            <source
              media="(min-width:800px)"
              srcset="../../assets/influencer-marketing/influencer-marketing-hero-banner.jpg"
            />
            <img
              src="../../assets/influencer-marketing/influencer-marketing-small-device.jpg"
              alt=""
            />
          </picture> */}
          <LazyLoad offset={100}>
            <picture>
              <source
                media="(min-width:800px)"
                srcSet="../../assets/influencer-marketing/influencer-marketing-hero-banner.jpg"
              />
              <img
                src="../../assets/influencer-marketing/influencer-marketing-small-device.jpg"
                alt="KARN Marketing Influencer Marketing Banner"
                loading="lazy"
                width="100%"
                height="auto"
              />
            </picture>
          </LazyLoad>
          <div className="influencer-marketing-hero-section-content">
            <div className="h1 m-0">
              Influencer <br /> Marketing
            </div>
          </div>
        </div>
        <div className=" influencer-marketing-header-section position-relative py-5">
          <div className="our-container">
            <div className="h5">Influencer Marketing</div>
            <p className="fw-bold text-capitalize">
              Reach New Audiences & Boost Engagement With{" "}
              <h1
                style={{
                  display: "inline-block",
                  fontSize: "1rem",
                  fontWeight: "600",
                }}
                className="m-0"
              >
                INFLUENCER MARKETING AGENCY IN NAVI MUMBAI
              </h1>
            </p>
            <p>
              As one of the top influencer marketing agencies in India, we
              create smart strategies that provide results. Influencer marketing
              has become a major player in the marketing world, and for good
              reason.
            </p>
            <p>
              At KARN Marketing Warfare LLP, we specialize in crafting impactful
              influencer marketing campaigns that deliver results. Our team of
              experts will Identify the Right Influencers: We meticulously
              select influencers who align with your brand values and target
              audience. Develop Compelling Strategies: We create tailored
              campaigns that resonate with your audience and achieve your
              marketing goals.
            </p>
            <p>
              Measure and Optimize: We track campaign performance, analyze data,
              and make necessary adjustments for optimal results.
            </p>
          </div>
        </div>
        <div className=" influencer-marketing-body-section py-5">
          <div className="our-container">
            <div className=" influencer-marketing-body-header-section">
              <div className="h5">Why</div>
              <div className="h2 text-capitalize">Influencer Marketing</div>
              <div className=" influencer-marketing-body-header-border"></div>
            </div>
            <div className=" influencer-marketing-body-card-section">
              <div className=" influencer-marketing-card-row">
                {influencerMarketingCardSection.map((item, id) => {
                  return (
                    <>
                      <div
                        className=" influencer-marketing-card-block"
                        key={id}
                      >
                        <div className=" influencer-marketing-card-icon">
                          <img src={item.influencerMarketingIconPath} alt="" />
                        </div>
                        <div className=" influencer-marketing-card-heading">
                          <div className="h5 text-capitalize">
                            {item.influencerMarketingTitle}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <ContactForm />
      </section>
    </>
  );
};

export default InfluencerMarketing;
