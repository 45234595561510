import React from "react";
import "./about.css";
import LazyLoad from "react-lazyload";
import MetaDescTag from "../../components/MetaDescTag";

const About = () => {
  return (
    <>
      <MetaDescTag
        title={"ABOUT US | KARN Marketing Warfare LLP"}
        description={
          "At KARN Marketing Warfare LLP, we are not just a digital marketing agency—we are your allies in the battlefield of online competition. Founded with a vision to empower businesses through innovative digital strategies, we specialize in creating campaigns that don’t just compete but dominate."
        }
      />
      <section className="about-section position-relative">
        <div className="about-hero-section">
          {/* <picture>
            <source
              media="(min-width:800px)"
              srcset="../../assets/about/about-hero-banner.jpg"
            />
            <img src="../../assets/about/about-small-device.jpg" alt="" />
          </picture> */}
          <LazyLoad offset={100}>
            <picture>
              <source
                media="(min-width:800px)"
                srcSet="../../assets/about/about-hero-banner.jpg"
              />
              <img
                src="../../assets/about/about-small-device.jpg"
                alt="KARN Marketing About Us Banner"
                loading="lazy"
                width="100%"
                height="auto"
              />
            </picture>
          </LazyLoad>
          <div className="about-hero-section-content">
            <div className="h1 m-0">About Us</div>
          </div>
        </div>
        <div className="about-main-section position-relative py-5">
          <div className="our-container">
            <div className="about-main-row">
              <div className="about-header-block">
                <div className="h5">Who We Are</div>
                <p className="mb-0">
                  At KARN Marketing Warfare LLP, we are not just a{" "}
                  <h1
                    style={{ display: "inline-block", fontSize: "1rem" }}
                    className="m-0"
                  >
                    digital marketing agency
                  </h1>{" "}
                  we are your allies in the battlefield of online competition.
                  Founded with a vision to empower businesses through innovative
                  digital strategies, we specialize in creating campaigns that
                  don’t just compete but dominate. Our name reflects our
                  philosophy: marketing is a form of warfare, and every brand
                  needs a strong, strategic partner to emerge victorious.
                </p>
              </div>
              <div className="about-body-block-section">
                <div className="about-body-block-top-section">
                  <div className="about-body-row">
                    <div className="about-body-block">
                      <div className="h5">Our Mission</div>
                      <p>
                        <span className="fw-bold">
                          Our mission is simple :{" "}
                        </span>
                        To equip businesses with the tools and strategies they
                        need to conquer their market. We are committed to
                        delivering cutting-edge digital marketing solutions that
                        drive growth, build brand authority, and maximize return
                        on investment. At KARN Marketing Warfare LLP, we don't
                        just aim for success; we aim for market leadership.
                      </p>
                    </div>
                    <div className="about-body-block">
                      <div className="h5">Our Vision</div>
                      <p>
                        KARN Marketing Warfare LLP is a gospel of ours to become
                        the foremost digital marketing force in the online
                        business world where every brand can compete not only to
                        survive but to thrive. We are aiming to transform the
                        traditional manner of communication between the brands
                        and consumers by coming up with new, advanced, and
                        data-supported strategies that will result in actual
                        success. Our goal is to be the leader in the digital
                        marketing field, and through this, we guarantee that
                        every client will get to the point of market domination
                        and sustainable growth.
                      </p>
                    </div>
                  </div>
                  <div className="about-body-other-block">
                    <div className="h5">Why Choose Us?</div>
                    <p>
                      <span className="fw-bold text-capitalize">
                        Proven Track Record :{" "}
                      </span>
                      We have a history of delivering successful campaigns
                      across various industries.
                    </p>
                    <p>
                      <span className="fw-bold text-capitalize">
                        Tailored Solutions :{" "}
                      </span>
                      No two businesses are alike. We create customized
                      strategies that cater to your specific needs.
                    </p>
                    <p>
                      <span className="fw-bold text-capitalize">
                        Cutting-Edge Tools :{" "}
                      </span>
                      We leverage the latest tools and technologies to ensure
                      your campaigns are effective and up-to-date.
                    </p>
                    <p className="mb-0">
                      <span className="fw-bold text-capitalize">
                        Client-Centric Focus :{" "}
                      </span>
                      Your success is our priority. We are committed to
                      providing exceptional service and support every step of
                      the way.
                    </p>
                  </div>
                </div>
                <div className="about-body-block-bottom position-relative">
                  <div className="h5">
                    Join the Battle for Market Leadership
                  </div>
                  <p>
                    Ready to take your digital marketing to the next level? KARN
                    Marketing Warfare LLP is here to lead the charge. Whether
                    you’re looking to increase brand awareness, drive more
                    traffic, or boost conversions, we have the expertise and the
                    strategies to make it happen.
                  </p>
                  <p className="mb-0">
                    Contact us today to learn more about how we can help you
                    conquer the competition and achieve your business goals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
