import React, { useState } from "react";
import "./home.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LazyLoad from "react-lazyload";
import { MdArrowRightAlt } from "react-icons/md";
import MetaDescTag from "../../components/MetaDescTag";
import { homeClients, homeServiceCardSection } from "../../staticData/data";

const Home = () => {
  // website design and developed info
  const websiteDevelopedBy = localStorage.setItem(
    "websiteDevelopedBy",
    process.env.REACT_APP_OWNER_FOR_DEVELOPMENT
  );
  
  const [activeField, setActiveField] = useState("webUrl");
  const [hoveredClient, setHoveredClient] = useState(homeClients[0]);
  const [formData, setFormData] = useState({
    webSiteUrl: "",
    phoneNo: "",
    email: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFocus = (field) => {
    setActiveField(field);
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.webSiteUrl) formErrors.webSiteUrl = "Website URL is required";
    if (!formData.phoneNo) formErrors.phoneNo = "Phone number is required";
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
    }
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/home/add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        if (response.ok) {
          const emailResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/home/userEmail`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
            }
          );
          const adminEmailResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/home/adminEmail`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
            }
          );

          if (emailResponse.ok && adminEmailResponse.ok) {
            toast.success("Your message has been sent successfully!", {
              autoClose: 1000,
              position: "bottom-right",
            });
            setFormData({
              webSiteUrl: "",
              phoneNo: "",
              email: "",
            });
            setErrors({});
          } else {
            toast.error("There was an error sending your message.", {
              autoClose: 1000,
              position: "bottom-right",
            });
          }
        } else {
          toast.error("There was an error sending your message.", {
            autoClose: 1000,
            position: "bottom-right",
          });
        }
      } catch (error) {
        toast.error("There was an error sending your message.", {
          autoClose: 1000,
          position: "bottom-right",
        });
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <>
      <MetaDescTag
        title={"KARN Marketing Warfare LLP"}
        description={
          "KARN Marketing Warfare LLP: Your strategic partner in digital dominance, crafting campaigns that conquer the competition."
        }
      />
      <section className="home-section">
        <div className="home-hero-section">
          <div className="home-hero-img-block">
            <LazyLoad offset={100}>
              <picture>
                <source
                  media="(min-width:840px)"
                  srcSet="../../assets/home/karn-hero-banner.jpg"
                />
                <source
                  media="(max-width:670px)"
                  srcSet="../../assets/home/home-small-device.jpg"
                />
                <img
                  src="../../assets/home/home-medium-device.jpg"
                  alt="KARN Marketing Homepage Banner"
                  loading="lazy"
                  width="100%"
                  height="auto"
                />
              </picture>
            </LazyLoad>
            <div className="home-hero-overlay"></div>
            <div className="home-hero-content-block our-container">
              <div className="home-hero-content">
                <p className="para0">Welcome to Karnllp</p>
                <h1 className="heading1">Karn Digital Marketing Agency.</h1>
                <p className="para1">
                  KARN Marketing Warfare LLP: Your strategic partner in digital
                  dominance, crafting campaigns that conquer the competition.
                </p>
                <div className="home-hero-links">
                  <Link to={"/about-us"} className="home-hero-learn-more-link">
                    <span>Learn More</span>
                    <MdArrowRightAlt className="home-hero-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-service-section position-relative py-5">
          <div className="our-container">
            <div className="home-service-heading-block mb-5">
              <div className="h5">Our Services</div>
              <div className="h2 text-capitalize">
                Take Your Business to the next Level
              </div>
            </div>
            <div className="home-service-body-block">
              <div className="home-service-body-row">
                {homeServiceCardSection.map((item, id) => {
                  return (
                    <Link
                      to={item.serviceURL}
                      key={id}
                      className="home-service-card-link"
                    >
                      <div className="home-service-card-block">
                        <div className="home-service-card-icon">
                          <img src={item.serviceImgPath} alt="" />
                        </div>
                        <div className="home-service-card-heading">
                          <div className="h5 text-capitalize">
                            {item.serviceTitle}
                          </div>
                        </div>
                        <div className="home-service-card-description">
                          <p className="mb-0">{item.serviceDescription}</p>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="home-website-checker-section py-5">
          <div className="our-container">
            <div className="home-website-checker-heading-block mb-5">
              <div className="h5">Our Web Performance</div>
              <div className="h2 text-capitalize">
                Check your Website Performance
              </div>
              <p>
                We at KARN Marketing Warfare LLP will thoroughly check your
                website and provide you with insights.
              </p>
            </div>
            <div className="home-website-checker-body-block">
              <div className="vector-container">
                {activeField === "webUrl" && (
                  <img
                    src="../../assets/home/website-checker/website-url.png"
                    alt="KARN Marketing Website Checker 1"
                    className="vector-image"
                  />
                )}
                {activeField === "mobileNo" && (
                  <img
                    src="../../assets/home/website-checker/mobile-number.png"
                    alt="KARN Marketing Website Checker 2"
                    className="vector-image"
                  />
                )}
                {activeField === "email" && (
                  <img
                    src="../../assets/home/website-checker/email.png"
                    alt="KARN Marketing Website Checker 3"
                    className="vector-image"
                  />
                )}
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-position-div-webURL">
                  <div className="d-flex align-align-items-center flex-column position-relative mb-4 contact-form-group home-website-checker-url">
                    <label
                      htmlFor="webSiteUrl"
                      className="text-capitalize mb-1"
                    >
                      Your Website URL <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="webSiteUrl"
                      name="webSiteUrl"
                      autoComplete="off"
                      className="px-2 rounded-0"
                      placeholder="https://karnllp.com/"
                      value={formData.webSiteUrl}
                      onChange={handleInputChange}
                      onFocus={() => handleFocus("webUrl")}
                    />
                    {errors.webSiteUrl && (
                      <div className="text-danger">{errors.webSiteUrl}</div>
                    )}
                  </div>
                </div>
                <div className="d-flex align-align-items-center flex-column position-relative mb-4 contact-form-group home-website-checker-mobile">
                  <div>
                    <label htmlFor="phoneNo" className="text-capitalize mb-1">
                      Your Mobile No <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      id="phoneNo"
                      name="phoneNo"
                      autoComplete="off"
                      className="px-2 rounded-0"
                      placeholder="000 000 0000"
                      value={formData.phoneNo}
                      onChange={handleInputChange}
                      onFocus={() => handleFocus("mobileNo")}
                    />
                    {errors.phoneNo && (
                      <div className="text-danger">{errors.phoneNo}</div>
                    )}
                  </div>
                </div>
                <div className="d-flex align-align-items-center flex-column position-relative mb-4 contact-form-group home-website-checker-email">
                  <div>
                    <label htmlFor="email" className="text-capitalize mb-1">
                      Your Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      autoComplete="off"
                      className="px-2 rounded-0"
                      placeholder="karnmarketingwarfarellp@gmail.com"
                      value={formData.email}
                      onChange={handleInputChange}
                      onFocus={() => handleFocus("email")}
                    />
                    {errors.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}
                  </div>
                </div>
                <button type="submit" className="contact-form-button">
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="home-clients-section py-5">
          <div className="our-container">
            <div className="home-clients-logo-header">
              <div className="h5">Our Clients</div>
              <p>
                At KARN Marketing Warfare LLP, we are proud to partner with a
                diverse range of clients from various industries. Our commitment
                to delivering tailored digital marketing solutions has helped us
                build long-lasting relationships based on trust, innovation, and
                measurable results.
              </p>
            </div>
            <div className="home-clients-logo-body">
              <div className="home-clients-logo-row">
                {homeClients.map((item, index) => (
                  <div
                    key={index}
                    className="home-clients-logo-block"
                  >
                    <img src={item.clientLogo} alt={`Client logo ${index}`} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
