import React from "react";
import "./pr-management.css";
import LazyLoad from "react-lazyload";
import ContactForm from "../../../components/ContactForm";
import MetaDescTag from "../../../components/MetaDescTag";
import {
  prManagementCardSection,
  prManagementParaDetails,
} from "../../../staticData/data";

const PRManagement = () => {
  return (
    <>
      <MetaDescTag
        title={"PR Management | KARN Marketing Warfare LLP"}
        description={
          "PR in the literal experience of the term manner Public Relations, the artwork of dealing with and controlling the discharge and spread of statistics between an person or an organisation and the common public, With its services like Brand positioning, Media, Strategic counseling."
        }
      />
      <section className="pr-management-section position-relative">
        <div className="pr-management-hero-section">
          {/* <picture>
            <source
              media="(min-width:800px)"
              srcset="../../assets/pr-management/pr-management-hero-banner.jpg"
            />
            <img
              src="../../assets/pr-management/pr-management-small-device.jpg"
              alt=""
            />
          </picture> */}
          <LazyLoad offset={100}>
            <picture>
              <source
                media="(min-width:800px)"
                srcSet="../../assets/pr-management/pr-management-hero-banner.jpg"
              />
              <img
                src="../../assets/pr-management/pr-management-small-device.jpg"
                alt="KARN Marketing PR Management Banner"
                loading="lazy"
                width="100%"
                height="auto"
              />
            </picture>
          </LazyLoad>
          <div className="pr-management-hero-section-content">
            <div className="h1 m-0">PR Management</div>
          </div>
        </div>
        <div className=" pr-management-header-section position-relative py-5">
          <div className="our-container">
            <div className="h5">PR Management</div>
            <p>
              PR in the literal experience of the term manner Public Relations,
              the artwork of dealing with and controlling the discharge and
              spread of statistics between an person or an organisation and the
              common public, With its services like Brand positioning, Media,
              Strategic counseling, Digital have an impact on, Crisis communique
              and Reputation control, a{" "}
              <h1
                style={{ display: "inline-block", fontSize: "1rem" }}
                className="m-0"
              >
                PR agency in Navi Mumbai
              </h1>{" "}
              guarantees all the help had to pitch in your business inside the
              marketplace, promotes it across nearby borders and establishes its
              reign within the minds of the centered clients.
            </p>
            <p
              className="fw-bold text-capitalize"
              style={{ color: "#0f1d55", fontSize: "1.5rem" }}
            >
              What Are The Importance Of PR Solutions In A Business?
            </p>
            <div className="pr-header-details-row">
              {prManagementParaDetails.map((item, id) => {
                return (
                  <>
                    <div className="pr-header-details-block" key={id}>
                      <p className="fw-bold text-capitalize">
                        {item.prManagementParaDetailsTitle}
                      </p>
                      <p>{item.prManagementParaDetailsDesc}</p>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
        <div className=" pr-management-body-section py-5">
          <div className="our-container">
            <div className=" pr-management-body-header-section">
              <div className="h5">Importance</div>
              <div className="h2 text-capitalize">
                Of PR Solutions In A Business
              </div>
              <div className=" pr-management-body-header-border"></div>
            </div>
            <div className=" pr-management-body-card-section">
              <div className=" pr-management-card-row">
                {prManagementCardSection.map((item, id) => {
                  return (
                    <>
                      <div className=" pr-management-card-block" key={id}>
                        <div className=" pr-management-card-icon">
                          <img src={item.prManagementIconPath} alt="" />
                        </div>
                        <div className=" pr-management-card-heading">
                          <div className="h5 text-capitalize">
                            {item.prManagementTitle}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <ContactForm />
      </section>
    </>
  );
};

export default PRManagement;
