import React, { useState, useEffect } from "react";
import "./modal.css";
import { toast } from "react-toastify";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";

const Modal = () => {
  const [formData, setFormData] = useState({
    userName: "",
    phoneNo: "",
    userEmail: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const modalElement = document.getElementById("staticBackdrop");

    if (modalElement) {
      modalElement.addEventListener("shown.bs.modal", handleModalOpen);
      modalElement.addEventListener("hidden.bs.modal", handleModalClose);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener("shown.bs.modal", handleModalOpen);
        modalElement.removeEventListener("hidden.bs.modal", handleModalClose);
      }
    };
  }, []);

  const handleModalOpen = () => {
    document.body.style.overflow = "hidden";
  };

  const handleModalClose = () => {
    // Ensure body scroll is restored
    document.body.style.overflow = "auto";
    
    // Remove any lingering backdrops
    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) {
      backdrop.parentNode.removeChild(backdrop);
    }
  };

  const closeModal = () => {
    const modalElement = document.getElementById("staticBackdrop");
    if (modalElement) {
      const modalInstance = bootstrap.Modal.getInstance(modalElement);
      if (modalInstance) {
        modalInstance.hide();
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.userName) formErrors.userName = "Name is required";
    if (!formData.phoneNo) formErrors.phoneNo = "Phone number is required";
    if (!formData.userEmail) {
      formErrors.userEmail = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.userEmail)) {
      formErrors.userEmail = "Email is invalid";
    }
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      try {
        const [response1, response2, response3] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/contact/add`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }),
          fetch(`${process.env.REACT_APP_API_URL}/contact/userEmail`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userName: formData.userName,
              userEmail: formData.userEmail,
              phoneNo: formData.phoneNo,
              message: formData.message,
            }),
          }),
          fetch(`${process.env.REACT_APP_API_URL}/contact/adminEmail`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userName: formData.userName,
              userEmail: formData.userEmail,
              phoneNo: formData.phoneNo,
              message: formData.message,
            }),
          }),
        ]);

        if (response1.ok && response2.ok && response3.ok) {
          toast.success("Your message has been sent successfully!", {
            autoClose: 1000,
            position: "bottom-right",
          });
          setFormData({
            userName: "",
            phoneNo: "",
            userEmail: "",
            message: "",
          });
          setErrors({});
          closeModal();
        } else {
          toast.error("There was an error sending your message.", {
            autoClose: 1000,
            position: "bottom-right",
          });
        }
      } catch (error) {
        toast.error("There was an error sending your message.", {
          autoClose: 1000,
          position: "bottom-right",
        });
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <div
      className="modal fade enquiry-modal"
      id="staticBackdrop"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">
              Enquiry Now
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="d-flex flex-column mb-4 enquiry-form-group">
                <input
                  type="text"
                  id="name"
                  name="userName"
                  autoComplete="off"
                  className="px-2 rounded-0"
                  placeholder="Your Name*"
                  value={formData.userName}
                  onChange={handleInputChange}
                />
                {errors.userName && (
                  <div className="text-danger">{errors.userName}</div>
                )}
              </div>
              <div className="d-flex flex-column mb-4 enquiry-form-group">
                <input
                  type="number"
                  id="number"
                  name="phoneNo"
                  autoComplete="off"
                  className="px-2 rounded-0"
                  placeholder="Your Number*"
                  value={formData.phoneNo}
                  onChange={handleInputChange}
                />
                {errors.phoneNo && (
                  <div className="text-danger">{errors.phoneNo}</div>
                )}
              </div>
              <div className="d-flex flex-column mb-4 enquiry-form-group">
                <input
                  type="email"
                  id="email"
                  name="userEmail"
                  autoComplete="off"
                  className="px-2 rounded-0"
                  placeholder="Your Email*"
                  value={formData.userEmail}
                  onChange={handleInputChange}
                />
                {errors.userEmail && (
                  <div className="text-danger">{errors.userEmail}</div>
                )}
              </div>
              <div className="d-flex align-items-center flex-column mb-4 enquiry-form-group">
                <textarea
                  name="message"
                  id="message"
                  rows={"7"}
                  autoComplete="off"
                  className="p-2 rounded-0"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <button
                type="submit"
                className="text-capitalize enquiry-form-button"
              >
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;









// import React, { useState, useEffect } from "react";
// import "./modal.css";
// import { toast } from "react-toastify";
// import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";

// const Modal = () => {
//   const [formData, setFormData] = useState({
//     userName: "",
//     phoneNo: "",
//     userEmail: "",
//     message: "",
//   });

//   const [errors, setErrors] = useState({});

//   useEffect(() => {
//     const modalElement = document.getElementById("staticBackdrop");

//     if (modalElement) {
//       modalElement.addEventListener("hidden.bs.modal", handleModalClose);
//     }

//     return () => {
//       if (modalElement) {
//         modalElement.removeEventListener("hidden.bs.modal", handleModalClose);
//       }
//     };
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const validateForm = () => {
//     let formErrors = {};
//     if (!formData.userName) formErrors.userName = "Name is required";
//     if (!formData.phoneNo) formErrors.phoneNo = "Phone number is required";
//     if (!formData.userEmail) {
//       formErrors.userEmail = "Email is required";
//     } else if (!/\S+@\S+\.\S+/.test(formData.userEmail)) {
//       formErrors.userEmail = "Email is invalid";
//     }
//     return formErrors;
//   };

//   const handleModalClose = () => {
//     // Ensure body scroll is restored
//     document.body.classList.remove("modal-open");
//     document.body.style.removeProperty("overflow");
//     document.body.style.removeProperty("padding-right");

//     // Remove any lingering backdrops
//     const backdrop = document.querySelector(".modal-backdrop");
//     if (backdrop) {
//       backdrop.parentNode.removeChild(backdrop);
//     }
//   };

//   const closeModal = () => {
//     const modalElement = document.getElementById("staticBackdrop");
//     if (modalElement) {
//       const modalInstance = bootstrap.Modal.getInstance(modalElement);
//       if (modalInstance) {
//         modalInstance.hide();
//       }
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formErrors = validateForm();
//     if (Object.keys(formErrors).length === 0) {
//       try {
//         const [response1, response2, response3] = await Promise.all([
//           fetch(`${process.env.REACT_APP_API_URL}/contact/add`, {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify(formData),
//           }),
//           fetch(`${process.env.REACT_APP_API_URL}/contact/userEmail`, {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify({
//               userName: formData.userName,
//               userEmail: formData.userEmail,
//               phoneNo: formData.phoneNo,
//               message: formData.message,
//             }),
//           }),
//           fetch(`${process.env.REACT_APP_API_URL}/contact/adminEmail`, {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify({
//               userName: formData.userName,
//               userEmail: formData.userEmail,
//               phoneNo: formData.phoneNo,
//               message: formData.message,
//             }),
//           }),
//         ]);

//         if (response1.ok && response2.ok && response3.ok) {
//           toast.success("Your message has been sent successfully!", {
//             autoClose: 1000,
//             position: "bottom-right",
//           });
//           setFormData({
//             userName: "",
//             phoneNo: "",
//             userEmail: "",
//             message: "",
//           });
//           setErrors({});
//           closeModal();
//         } else {
//           toast.error("There was an error sending your message.", {
//             autoClose: 1000,
//             position: "bottom-right",
//           });
//         }
//       } catch (error) {
//         toast.error("There was an error sending your message.", {
//           autoClose: 1000,
//           position: "bottom-right",
//         });
//       }
//     } else {
//       setErrors(formErrors);
//     }
//   };

//   return (
//     <div
//       className="modal fade enquiry-modal"
//       id="staticBackdrop"
//       tabIndex="-1"
//       aria-labelledby="staticBackdropLabel"
//       aria-hidden="true"
//       data-bs-keyboard="false"
//       data-bs-backdrop="static"
//     >
//       <div className="modal-dialog modal-dialog-centered">
//         <div className="modal-content">
//           <div className="modal-header">
//             <h1 className="modal-title fs-5" id="staticBackdropLabel">
//               Enquiry Now
//             </h1>
//             <button
//               type="button"
//               className="btn-close"
//               data-bs-dismiss="modal"
//               aria-label="Close"
//             ></button>
//           </div>
//           <div className="modal-body">
//             <form onSubmit={handleSubmit}>
//               <div className="d-flex flex-column mb-4 enquiry-form-group">
//                 <input
//                   type="text"
//                   id="name"
//                   name="userName"
//                   autoComplete="off"
//                   className="px-2 rounded-0"
//                   placeholder="Your Name*"
//                   value={formData.userName}
//                   onChange={handleInputChange}
//                 />
//                 {errors.userName && (
//                   <div className="text-danger">{errors.userName}</div>
//                 )}
//               </div>
//               <div className="d-flex flex-column mb-4 enquiry-form-group">
//                 <input
//                   type="number"
//                   id="number"
//                   name="phoneNo"
//                   autoComplete="off"
//                   className="px-2 rounded-0"
//                   placeholder="Your Number*"
//                   value={formData.phoneNo}
//                   onChange={handleInputChange}
//                 />
//                 {errors.phoneNo && (
//                   <div className="text-danger">{errors.phoneNo}</div>
//                 )}
//               </div>
//               <div className="d-flex flex-column mb-4 enquiry-form-group">
//                 <input
//                   type="email"
//                   id="email"
//                   name="userEmail"
//                   autoComplete="off"
//                   className="px-2 rounded-0"
//                   placeholder="Your Email*"
//                   value={formData.userEmail}
//                   onChange={handleInputChange}
//                 />
//                 {errors.userEmail && (
//                   <div className="text-danger">{errors.userEmail}</div>
//                 )}
//               </div>
//               <div className="d-flex align-items-center flex-column mb-4 enquiry-form-group">
//                 <textarea
//                   name="message"
//                   id="message"
//                   rows={"7"}
//                   autoComplete="off"
//                   className="p-2 rounded-0"
//                   placeholder="Your Message"
//                   value={formData.message}
//                   onChange={handleInputChange}
//                 ></textarea>
//               </div>
//               <button
//                 type="submit"
//                 className="text-capitalize enquiry-form-button"
//               >
//                 Send
//               </button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Modal;










// // import React, { useState } from "react";
// // import "./modal.css";
// // import { toast } from "react-toastify";
// // import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";

// // const Modal = () => {
// //   const [formData, setFormData] = useState({
// //     userName: "",
// //     phoneNo: "",
// //     userEmail: "",
// //     message: "",
// //   });

// //   const [errors, setErrors] = useState({});

// //   const handleInputChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormData({ ...formData, [name]: value });
// //   };

// //   const validateForm = () => {
// //     let formErrors = {};
// //     if (!formData.userName) formErrors.userName = "Name is required";
// //     if (!formData.phoneNo) formErrors.phoneNo = "Phone number is required";
// //     if (!formData.userEmail) {
// //       formErrors.userEmail = "Email is required";
// //     } else if (!/\S+@\S+\.\S+/.test(formData.userEmail)) {
// //       formErrors.userEmail = "Email is invalid";
// //     }
// //     return formErrors;
// //   };

// //   // const closeModal = () => {
// //   //   const modalElement = document.getElementById("staticBackdrop");
// //   //   if (modalElement) {
// //   //     const modalInstance = bootstrap.Modal.getInstance(modalElement);
// //   //     if (modalInstance) {
// //   //       modalInstance.hide();
// //   //     }
// //   //   }
// //   //   // Cleanup lingering backdrop
// //   //   const backdrop = document.querySelector(".modal-backdrop");
// //   //   if (backdrop) {
// //   //     backdrop.parentNode.removeChild(backdrop);
// //   //   }
// //   //   // Ensure body scroll is restored
// //   //   document.body.classList.remove("modal-open");
// //   //   document.body.style.removeProperty("overflow");
// //   //   document.body.style.removeProperty("padding-right");
// //   // };
// //   const closeModal = () => {
// //     const modalElement = document.getElementById("staticBackdrop");
// //     if (modalElement) {
// //       const modalInstance = bootstrap.Modal.getInstance(modalElement);
// //       if (modalInstance) {
// //         modalInstance.hide();
// //       }
// //     }
  
// //     // Cleanup lingering backdrop
// //     const backdrop = document.querySelector(".modal-backdrop");
// //     if (backdrop) {
// //       backdrop.parentNode.removeChild(backdrop);
// //     }
  
// //     // Ensure body scroll is restored
// //     document.body.classList.remove("modal-open");
// //     document.body.style.removeProperty("overflow");
// //     document.body.style.removeProperty("padding-right");
  
// //     // Ensure any lingering data-bs-toggle classes are removed
// //     modalElement.classList.remove("show");
// //     modalElement.style.display = "none";
// //     document.body.style.overflow = "";  // Remove the overflow hidden
// //   };
  

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     const formErrors = validateForm();
// //     if (Object.keys(formErrors).length === 0) {
// //       try {
// //         const [response1, response2, response3] = await Promise.all([
// //           fetch(`${process.env.REACT_APP_API_URL}/contact/add`, {
// //             method: "POST",
// //             headers: {
// //               "Content-Type": "application/json",
// //             },
// //             body: JSON.stringify(formData),
// //           }),
// //           fetch(`${process.env.REACT_APP_API_URL}/contact/userEmail`, {
// //             method: "POST",
// //             headers: {
// //               "Content-Type": "application/json",
// //             },
// //             body: JSON.stringify({
// //               userName: formData.userName,
// //               userEmail: formData.userEmail,
// //               phoneNo: formData.phoneNo,
// //               message: formData.message,
// //             }),
// //           }),
// //           fetch(`${process.env.REACT_APP_API_URL}/contact/adminEmail`, {
// //             method: "POST",
// //             headers: {
// //               "Content-Type": "application/json",
// //             },
// //             body: JSON.stringify({
// //               userName: formData.userName,
// //               userEmail: formData.userEmail,
// //               phoneNo: formData.phoneNo,
// //               message: formData.message,
// //             }),
// //           })
// //         ]);
  
// //         if (response1.ok && response2.ok && response3.ok) {
// //           toast.success("Your message has been sent successfully!", {
// //             autoClose: 1000,
// //             position: "bottom-right",
// //           });
// //           setFormData({
// //             userName: "",
// //             phoneNo: "",
// //             userEmail: "",
// //             message: "",
// //           });
// //           setErrors({});
// //           closeModal();
// //         } else {
// //           toast.error("There was an error sending your message.", {
// //             autoClose: 1000,
// //             position: "bottom-right",
// //           });
// //         }
// //       } catch (error) {
// //         toast.error("There was an error sending your message.", {
// //           autoClose: 1000,
// //           position: "bottom-right",
// //         });
// //       }
// //     } else {
// //       setErrors(formErrors);
// //     }
// //   };
  
// //   // const handleSubmit = async (e) => {
// //   //   e.preventDefault();
// //   //   const formErrors = validateForm();
// //   //   if (Object.keys(formErrors).length === 0) {
// //   //     try {
// //   //       const [response1, response2, response3] = await Promise.all([
// //   //         fetch(`${process.env.REACT_APP_API_URL}/contact/add`, {
// //   //           method: "POST",
// //   //           headers: {
// //   //             "Content-Type": "application/json",
// //   //           },
// //   //           body: JSON.stringify(formData),
// //   //         }),
// //   //         fetch(`${process.env.REACT_APP_API_URL}/contact/userEmail`, {
// //   //           method: "POST",
// //   //           headers: {
// //   //             "Content-Type": "application/json",
// //   //           },
// //   //           body: JSON.stringify({
// //   //             userName: formData.userName,
// //   //             userEmail: formData.userEmail,
// //   //             phoneNo: formData.phoneNo,
// //   //             message: formData.message,
// //   //           }),
// //   //         })
// //   //       ]);

// //   //       if (response1.ok && response2.ok) {
// //   //         toast.success("Your message has been sent successfully!", {
// //   //           autoClose: 1000,
// //   //           position: "bottom-right",
// //   //         });
// //   //         setFormData({
// //   //           userName: "",
// //   //           phoneNo: "",
// //   //           userEmail: "",
// //   //           message: "",
// //   //         });
// //   //         setErrors({});
// //   //         closeModal();
// //   //       } else {
// //   //         toast.error("There was an error sending your message.", {
// //   //           autoClose: 1000,
// //   //           position: "bottom-right",
// //   //         });
// //   //       }
// //   //     } catch (error) {
// //   //       toast.error("There was an error sending your message.", {
// //   //         autoClose: 1000,
// //   //         position: "bottom-right",
// //   //       });
// //   //     }
// //   //   } else {
// //   //     setErrors(formErrors);
// //   //   }
// //   // };

// //   return (
// //     <div
// //       className="modal fade enquiry-modal"
// //       id="staticBackdrop"
// //       tabIndex="-1"
// //       aria-labelled-by="staticBackdropLabel"
// //       aria-hidden="true"
// //       data-bs-keyboard="false"
// //       data-bs-backdrop="static"
// //     >
// //       <div className="modal-dialog modal-dialog-centered">
// //         <div className="modal-content">
// //           <div className="modal-header">
// //             <h1 className="modal-title fs-5" id="staticBackdropLabel">
// //               Enquiry Now
// //             </h1>
// //             <button
// //               type="button"
// //               className="btn-close"
// //               data-bs-dismiss="modal"
// //               aria-label="Close"
// //             ></button>
// //           </div>
// //           <div className="modal-body">
// //             <form onSubmit={handleSubmit}>
// //               <div className="d-flex flex-column mb-4 enquiry-form-group">
// //                 <input
// //                   type="text"
// //                   id="name"
// //                   name="userName"
// //                   autoComplete="off"
// //                   className="px-2 rounded-0"
// //                   placeholder="Your Name*"
// //                   value={formData.userName}
// //                   onChange={handleInputChange}
// //                 />
// //                 {errors.userName && (
// //                   <div className="text-danger">{errors.userName}</div>
// //                 )}
// //               </div>
// //               <div className="d-flex flex-column mb-4 enquiry-form-group">
// //                 <input
// //                   type="number"
// //                   id="number"
// //                   name="phoneNo"
// //                   autoComplete="off"
// //                   className="px-2 rounded-0"
// //                   placeholder="Your Number*"
// //                   value={formData.phoneNo}
// //                   onChange={handleInputChange}
// //                 />
// //                 {errors.phoneNo && (
// //                   <div className="text-danger">{errors.phoneNo}</div>
// //                 )}
// //               </div>
// //               <div className="d-flex flex-column mb-4 enquiry-form-group">
// //                 <input
// //                   type="email"
// //                   id="email"
// //                   name="userEmail"
// //                   autoComplete="off"
// //                   className="px-2 rounded-0"
// //                   placeholder="Your Email*"
// //                   value={formData.userEmail}
// //                   onChange={handleInputChange}
// //                 />
// //                 {errors.userEmail && (
// //                   <div className="text-danger">{errors.userEmail}</div>
// //                 )}
// //               </div>
// //               <div className="d-flex align-items-center flex-column mb-4 enquiry-form-group">
// //                 <textarea
// //                   name="message"
// //                   id="message"
// //                   rows={"7"}
// //                   autoComplete="off"
// //                   className="p-2 rounded-0"
// //                   placeholder="Your Message"
// //                   value={formData.message}
// //                   onChange={handleInputChange}
// //                 ></textarea>
// //               </div>
// //               <button
// //                 type="submit"
// //                 className="text-capitalize enquiry-form-button"
// //               >
// //                 Send
// //               </button>
// //             </form>
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Modal;
