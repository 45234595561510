import React from "react";
import "./branding.css";
import LazyLoad from "react-lazyload";
import ContactForm from "../../../components/ContactForm";
import MetaDescTag from "../../../components/MetaDescTag";
import { brandingCardSection } from "../../../staticData/data";

const Branding = () => {
  return (
    <>
      <MetaDescTag
        title={"BRANDING|KARN Marketing Warfare LLP"}
        description={
          "A brand desires to be constantly better and tries to be monitored very well to excel in this aggressive worldwide. As a part of brand advertising, it’s important to build, engage, and persist the logo picture inside the consumer’s mind.H1: Karn Digital Marketing Agency."
        }
      />
      <section className="branding-section position-relative">
        <div className="branding-hero-section">
          {/* <picture>
            <source
              media="(min-width:800px)"
              srcset="../../assets/branding/branding-hero-banner.jpg"
            />
            <img src="../../assets/branding/branding-small-device.jpg" alt="" />
          </picture> */}
          <LazyLoad offset={100}>
            <picture>
              <source
                media="(min-width:800px)"
                srcSet="../../assets/branding/branding-hero-banner.jpg"
              />
              <img
                src="../../assets/branding/branding-small-device.jpg"
                alt="KARN Marketing Branding Banner"
                loading="lazy"
                width="100%"
                height="auto"
              />
            </picture>
          </LazyLoad>
          <div className="branding-hero-section-content">
            <div className="h1 m-0">Branding</div>
          </div>
        </div>
        <div className="branding-header-section position-relative py-5">
          <div className="our-container">
            <div className="h5">Branding</div>
            <p>
              A brand desires to be constantly better and tries to be monitored
              very well to excel in this aggressive worldwide. As a part of
              brand advertising, it’s important to build, engage, and persist
              the logo picture inside the consumer’s mind. As a{" "}
              <h1
                style={{ display: "inline-block", fontSize: "1rem" }}
                className="m-0"
              >
                advertising agencies in navi mumbai
              </h1>
              , we behavior huge brand research, observe your business, and
              create an interactive logo marketing approach to goal your
              customers on and offline.
            </p>
            <p>
              We’ll examine your enterprise market, recognize competition have a
              look at your brand metrics, and renovate your logo marketing
              strategies for a higher purchaser fee. The task of a personal
              branding agency is to provide an entire makeover in your
              commercial enterprise from brand layout, and marketing fabric
              layout to the example of the brand digitally.
            </p>
          </div>
        </div>
        <div className="branding-body-section py-5">
          <div className="our-container">
            <div className="branding-body-header-section">
              <div className="h5">What We Do</div>
              <div className="h2 text-capitalize">
                Take Your Business To The Next Level
              </div>
              <div className="branding-body-header-border"></div>
            </div>
            <div className="branding-body-card-section">
              <div className="branding-card-row">
                {brandingCardSection.map((item, id) => {
                  return (
                    <>
                      <div className="branding-card-block" key={id}>
                        <div className="branding-card-icon">
                          <img src={item.brandingIconPath} alt="" />
                        </div>
                        <div className="branding-card-heading">
                          <div className="h5 text-capitalize">
                            {item.brandingTitle}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <ContactForm />
      </section>
    </>
  );
};

export default Branding;
